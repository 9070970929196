import ImageWithDefault from '@aims/shared/components/ImageWithDefault';
import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { Skeleton, Typography } from 'antd';
import React, { useMemo } from 'react';
import settings from '../../../../settings';
import { useWindowDimensions } from '../../../../shared/window-dimensions';
import useDefaultProjectPhoto from '../../../holistic-projects/use-default-project-photo';

const { Title, Text } = Typography;

function useSrcSetImages(photo, defaultPhoto) {
  const images = useMemo(() => {
    if (photo?.images) {
      const imgLg = photo?.images.find((p) => p.sizeCode == 'LG');
      const imgMd = photo?.images.find((p) => p.sizeCode == 'MD');
      const imgSm = photo?.images.find((p) => p.sizeCode == 'SM');
      const imgThumb = photo?.images.find((p) => p.sizeCode == 'THUMB');
      return {
        large: imgLg?.url || photo.url || imgThumb?.url,
        medium: imgMd?.url || photo.url || imgThumb?.url,
        small: imgSm?.url || photo.url || imgThumb?.url,
      };
    }
    return defaultPhoto;
  }, [photo, defaultPhoto]);
  return images;
}

function ProjectBanner({ project }) {
  const bannerImages = useSrcSetImages(project?.primaryBanner, undefined);
  const defaultPhoto = useDefaultProjectPhoto(project);
  const primaryImages = useSrcSetImages(project?.primaryPhoto, defaultPhoto);
  const projectType =
    project &&
    HolisticProjectTypes[project.typeOfProject] &&
    HolisticProjectTypes[project.typeOfProject].label;
  const {
    large: bannerImgLg,
    medium: bannerImgMd,
    small: bannerImgSm,
  } = bannerImages || {};
  const {
    large: photoImgLg,
    medium: photoImgMd,
    small: photoImgSm,
  } = primaryImages || {};
  const dimensions = useWindowDimensions();
  return (
    <div className="container">
      {dimensions.width >= 840 ? (
        <>
          {bannerImages ? (
            <img
              className="banner-img"
              src={bannerImgLg}
              srcSet={`${bannerImgSm} 720w,
        ${bannerImgMd} 1080w,
        ${bannerImgLg} 1920w`}
              sizes={`(max-width: ${settings.screenSizes.md600}px) 720w,
        (max-width: ${settings.screenSizes.lg992}px) 1080w,
        1920w`}
            />
          ) : (
            <div
              className="banner-img"
              style={{
                background: `linear-gradient(to right, ${settings.colors.holisticDarkGreen}, ${settings.colors.holisticGreen})`,
              }}
            />
          )}
        </>
      ) : (
        <>
          <img
            className="banner-img"
            src={photoImgLg}
            srcSet={`${photoImgSm} 720w,
        ${photoImgMd} 1080w,
        ${photoImgLg} 1920w`}
            sizes={`(max-width: ${settings.screenSizes.md600}px) 720w,
        (max-width: ${settings.screenSizes.lg992}px) 1080w,
        1920w`}
          />
        </>
      )}

      <div className="card">
        <div className="row1">
          {dimensions.width >= 840 && (
            <ImageWithDefault
              imageUrl={project && project.primaryThumbnailUrl}
              fallbackUrl={defaultPhoto}
              alt={project && project.projectName}
              style={{
                objectFit: 'cover',
                height: '100%',
                aspectRatio: '1/1',
              }}
            />
          )}
          {project ? (
            <div className="right">
              <Title
                level={3}
                style={{ marginBottom: 0, maxWidth: 600 }}
                ellipsis={dimensions.width >= 840}
              >
                {project.projectName}
              </Title>
              {projectType && (
                <div
                  style={{
                    fontSize: 18,
                  }}
                >
                  {projectType}
                </div>
              )}
              {project.region && (
                <div>
                  <Text
                    style={{
                      color: settings.colors.holisticText,
                      fontSize: 16,
                    }}
                  >
                    {project.region}
                  </Text>
                </div>
              )}
            </div>
          ) : (
            <Skeleton active />
          )}
        </div>
      </div>

      <style jsx>{`
        .container {
          position: relative;
          margin-bottom: 32px;
        }
        .banner-img {
          object-fit: cover;
          max-width: 3000px;
          margin-right: auto;
          margin-left: auto;
          aspect-ratio: 7 / 2;
          width: 100%;
          min-height: 140px;
        }
        .right {
          padding: 32px;
          display: flex;
          justify-content: left;
          align-items: left;
          flex-direction: column;
          flex: 1;
        }
        .card {
          background: white;
          position: absolute;
          bottom: 0px;
          left: 10%;
          min-width: 40%;
          height: 140px;
        }
        .row1 {
          display: flex;
          height: 140px;
        }
        @media (max-width: 840px) {
          .card {
            position: static;
            height: unset;
          }
          .row1 {
            height: unset;
          }
          .right {
            padding: 16px;
          }
          .banner-img {
            aspect-ratio: 3 / 2;
          }
        }
        .row2 {
          margin-top: 16px;
          margin-bottom: 16px;
          text-align: left;
        }
      `}</style>
    </div>
  );
}

export default ProjectBanner;
